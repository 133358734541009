<template>
  <div>
    <v-card flat class="mt-4 px-8">
      <v-row class="py-3" v-if="180-$moment().diff($moment(detail.usedAt), 'days') > 0">
        <v-col cols="2" class="pt-2 mr-4">
          <!--            <v-progress-linear :value="($moment().diff($moment(detail.usedAt || new Date()), 'days') * 100)/detail.periodFirstCopy" height="8" color="#4770BD" rounded>-->
          <v-progress-linear :value="($moment().diff($moment(detail.usedAt || new Date()), 'days') * 100)/180" height="8" color="#4770BD" rounded>
          </v-progress-linear>
        </v-col>
        <v-col cols="4">
          <!--            <span class="caption" style="color:#4770BD">캠페인 완료까지 {{detail.periodFirstCopy - $moment().diff($moment(detail.usedAt || new Date()), 'days')}}일 남았습니다.</span>-->
          <span class="caption" style="color:#4770BD">캠페인 완료까지 {{180 - $moment().diff($moment(detail.usedAt || new Date()), 'days')}}일 남았습니다.</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" style="font-size: 13px;color: #808080;">
          {{$moment.tz('Asia/Seoul').format('YYYY. MM. DD')}} 기준
        </v-col>
      </v-row>
      <v-row class="py-3" v-else>
        <v-col cols="6" class="pt-2 mr-4 text-center" style="color: #C2C2C2">
          캠페인이 완료되었습니다.
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-5">
        <v-col cols="2">
          <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '128' : '64'">
            <img v-if="(detail.Youtuber || {}).thumbnail" :src="(detail.Youtuber || {}).thumbnail"/>
            <img v-else src="@/assets/img/matching-placeholder.jpg">
          </v-avatar>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '5'" :class="$vuetify.breakpoint.mdAndUp ? 'pt-5' : 'pt-2'">
          <v-row>
            <v-col cols="2">
              <naverIcon v-if="detail.taggableType === 'naver'"/>
              <instaIcon v-if="detail.taggableType === 'insta'"/>
              <youtubeIcon v-if="detail.taggableType === 'youtube'"/>
            </v-col>
            <v-col v-if="detail.Youtuber" class="subheading">{{(detail.Youtuber || {}).title}}</v-col>
          </v-row>
          <v-row>
            <v-chip x-small outlined color="#34CD89" class="ma-0 mr-1 mt-2"
                    v-for="(category, idx) in JSON.parse(detail.Youtuber.topCategory)" :key="idx">{{category}}</v-chip>
          </v-row>
          <v-row>
            <v-list two-line class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-content class="pt-1">
                  <v-list-item-title style="font-size: 10px; color: #808080">구독자</v-list-item-title>
                  <v-list-item-subtitle class="caption">{{(detail.Youtuber || {}).subscriberCount | numFormat}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pt-1">
                  <v-list-item-title style="font-size: 10px; color: #808080">수락률/평점</v-list-item-title>
                  <v-list-item-subtitle v-if="detail.Youtuber" class="caption">{{detail.Youtuber.acceptRate}}/{{detail.Youtuber.rating}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row class="subheading" style="color: #3A3A3A">
            등록 후기
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row style="height:140px">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '7'">
              <v-img v-if="(detail || {}).reviewVideo" width="240" height="140" :src="(detail || {}).reviewVideo.thumbnail ? (detail || {}).reviewVideo.thumbnail : 'https://via.placeholder.com/320x160.jpg?text=Supermebers'"></v-img>
            </v-col>
            <v-col class="px-4 my-auto">
              <v-row v-if="(detail || {}).reviewVideo" class="justify-space-between" justify="center" align="center">
                {{(detail || {}).reviewVideo.title}}
              </v-row>
              <v-row class="ma-2">
                <a :href="detail.review" target="_blank"><v-icon>open_in_new</v-icon></a>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
          <v-row class="subheading mb-1" style="color: #3A3A3A">
            총 조회수
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row style="font-size: 32px; color: #4463BB">
            <v-col class="text-center">
              {{(((detail || {}).reviewVideo || {}).viewCount || 0) | numFormat}}
            </v-col>
          </v-row>
          <v-row style="font-size: 13px; color: #808080">
            <v-col class="text-center">
              게시물 총 조회수
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-2">
          <v-row class="subheading" style="color: #3A3A3A">
            일간 조회수
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row class="pl-6">
            <naverChart :width="$vuetify.breakpoint.mdAndUp ? 600 : 400" :height="200" :data="viewChartData"></naverChart>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
          <v-row class="subheading mb-1" style="color: #3A3A3A">
            총 좋아요 / 댓글 수
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row style="font-size: 32px; color: #4463BB">
            <v-col class="text-center">
              {{(((detail.reviewVideo || {}).likeCount || 0) + ((detail.reviewVideo || {}).commentCount || 0)) | numFormat}}
            </v-col>
          </v-row>
          <v-row style="font-size: 13px; color: #808080">
            <v-col class="text-center">
              영상에 등록된 좋아요 / 댓글 수
            </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-2">
          <v-row class="subheading" style="color: #3A3A3A">
            일간 좋아요 / 댓글 수
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row class="pl-6">
            <naverChart :width="$vuetify.breakpoint.mdAndUp ? 600 : 400" :height="200" :data="youtubeLikeChartData"></naverChart>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import naverChart from '@/components/campaign/detail/report/NaverChart'

  export default {
    name: 'Youtubereportdetail',
    props: ['detail', 'viewChartData', 'youtubeLikeChartData'],
    components: {
      naverIcon: () => import('@/assets/img/campaign/naver_c.svg'),
      instaIcon: () => import('@/assets/img/campaign/insta_c.svg'),
      youtubeIcon: () => import('@/assets/img/campaign/youtube_c.svg'),
      naverChart
    },
    data() {
      return {
      }
    }
  };
</script>
