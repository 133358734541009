<template>
  <v-card flat class="mt-4 pa-8">
    <v-row class="mb-8" style="color: #3A3A3A">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '7'" style="font-size:18px">캠페인 전체 기간 보고서</v-col>
      <v-spacer></v-spacer>
      <v-col cols="4" class="text-right mt-1">{{$moment.tz('Asia/Seoul').subtract(6, 'month').format('YYYY. MM. DD')}} - {{$moment.tz('Asia/Seoul').format('YYYY. MM. DD')}}</v-col>
      
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          집행 금액
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (pointSum) | numFormat}}원
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          리뷰 등록된 건의 제안금액 합계
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          조회수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (viewCount) | numFormat}}회
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          누적조회수
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          클릭수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{clickCount | numFormat}}회
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          누적 구매링크 클릭수
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          CPV
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (viewCount ? Math.round(pointSum/viewCount) : 0) | numFormat}}원
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          조회수 1회당 소요 비용<br>캠페인 초기의 CPV는 최종 CPV보다 높으며 누적 조회수가 증가할수록 CPV도 감소합니다.
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          CPC
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (clickCount ? Math.round(pointSum/clickCount) : 0) | numFormat}}원
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          구매링크 클릭수 1회당 소요된 비용<br>캠페인 초기의 CPC는 최종 CPC보다 높으며 누적 클릭수가 증가할수록 CPC도 감소합니다.
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          CTR
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ viewCount ? ((Math.floor(clickCount*10000/viewCount))/100) : 0}}%
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          조회수 대비 클릭률
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          좋아요
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (likeCount) | numFormat}}개
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          누적 좋아요 수
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          댓글
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ commentCount | numFormat}}개
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          누적 댓글수
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-12 mb-4" style="color: #3A3A3A">
      <v-col cols="3" class="d-flex align-center" style="font-size:18px">캠페인 기간별 보고서</v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
        <v-btn-toggle v-model="duration" mandatory>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(0, 0)">
            전체
          </v-btn>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(0, 1)">
            1주
          </v-btn>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(1, 0)">
            1개월
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex align-center mr-8" :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
        <DatePicker :date="startDate" @onDateChangeHandler="v => onDateChangeHandler(v,'start')" :maxDate="endDate"/>
        <div style="margin : 0 8px">-</div>
        <DatePicker :date="endDate" @onDateChangeHandler="v => onDateChangeHandler(v,'end')" :minDate="startDate"/>
      </v-col>
      <v-btn @click="csvDownload" color="rouge" flat outlined>
        <v-icon>download</v-icon>
            보고서 다운받기
      </v-btn>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          조회수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row style="font-size: 32px; color: #4463BB">
          <v-col>
            {{_.sumBy(viewChartData[0].data, 'y') | numFormat}}회
          </v-col>
        </v-row>
        <v-row style="font-size: 13px; color: #808080">
          <v-col>
            기간 내 발생한 조회수
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pr-2 mt-8">
        <v-row class="subheading" style="color: #3A3A3A">
          조회수 기록
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="pl-6">
          <naverChart :width="$vuetify.breakpoint.mdAndUp ? 600 : 400" :height="200" :data="viewChartData"></naverChart>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          클릭 수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row style="font-size: 32px; color: #4463BB">
          <v-col>
            {{_.sumBy(clickChartData[0].data, 'y') | numFormat}}회
          </v-col>
        </v-row>
        <v-row style="font-size: 13px; color: #808080">
          <v-col>
            기간 내 발생한 구매링크 클릭수
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pr-2 mt-8">
        <v-row class="subheading" style="color: #3A3A3A">
          클릭수 기록
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="pl-6">
          <naverChart :width="$vuetify.breakpoint.mdAndUp ? 600 : 400" :height="200" :data="clickChartData"></naverChart>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          좋아요/댓글수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row style="font-size: 32px; color: #4463BB">
          <v-col>
            {{ _.sumBy(likeChartData[0].data, 'y') | numFormat}}개
          </v-col>
        </v-row>
        <v-row style="font-size: 13px; color: #808080">
          <v-col>
            기간 내 발생한 좋아요, 댓글 수 합계
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pr-2 mt-8">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          좋아요/댓글수 기록
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="pl-6">
          <naverChart :width="$vuetify.breakpoint.mdAndUp ? 600 : 400" :height="200" :data="likeChartData"></naverChart>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="(maxView || {}).reviewVideo" :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading" style="color: #3A3A3A">
          조회수 가장 많은 게시물
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="12">
            <v-img v-if="(maxView || {}).reviewVideo" aspect-ratio="2" :src="(maxView || {}).reviewVideo.thumbnail ? (maxView || {}).reviewVideo.thumbnail : 'https://via.placeholder.com/320x160.jpg?text=Supermebers'"></v-img>
            <v-row v-if="(maxView || {}).reviewVideo" class="justify-space-between mt-2" justify="center" align="center">
              {{(maxView || {}).reviewVideo.title}}
              <a class="pa-4" :href="maxView.review" target="_blank"><v-icon>open_in_new</v-icon></a>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="(maxClick || {}).reviewVideo" :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading" style="color: #3A3A3A">
          클릭수 가장 많은 게시물
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="12">
            <v-img v-if="(maxClick || {}).reviewVideo" aspect-ratio="2" :src="(maxClick || {}).reviewVideo.thumbnail ? (maxClick || {}).reviewVideo.thumbnail : 'https://via.placeholder.com/320x160.jpg?text=Supermebers'"></v-img>
            <v-row v-if="(maxClick || {}).reviewVideo" class="justify-space-between mt-2" justify="center" align="center">
              {{(maxClick || {}).reviewVideo.title}}
              <a class="pa-4" :href="maxClick.review" target="_blank"><v-icon>open_in_new</v-icon></a>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="(maxComment || {}).reviewVideo" :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading" style="color: #3A3A3A">
          댓글 가장 많은 영상
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="12">
            <v-img v-if="(maxComment || {}).reviewVideo" aspect-ratio="2" :src="(maxComment || {}).reviewVideo.thumbnail ? (maxComment || {}).reviewVideo.thumbnail : 'https://via.placeholder.com/320x160.jpg?text=Supermebers'"></v-img>
            <v-row v-if="(maxComment || {}).reviewVideo" class="justify-space-between mt-2" justify="center" align="center">
              {{(maxComment || {}).reviewVideo.title}}
              <a class="pa-4" :href="maxComment.review" target="_blank"><v-icon>open_in_new</v-icon></a>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import naverChart from '@/components/campaign/detail/report/NaverChart'
import DatePicker from '@/components/campaign/detail/report/DatePicker'

  export default {
    name: 'YoutubeAllreport',
    props: ['campaign', 'propositions', 'stats'],
    components: {
      naverChart,
      DatePicker
    },
    data() {
      return {
        viewChartData: [],
        clickChartData: [],
        likeChartData: [],
        keywords: [],
        keywordsAll: false,
        maxView: {},
        maxClick: {},
        maxComment: {},
        duration: 0,
        startDate: '',
        endDate: ''
      }
    },
    computed: {
      viewCount() {
        return this._.sumBy(this.stats.all || [], 'views');
      },
      clickCount() {
        return this._.sumBy(this.stats.all || [], 'clicks');
      },
      pointSum() {
        return this._.sumBy(this.propositions, 'point');
      },
      likeCount() {
        return this._.sumBy(this.stats.all || [], 'likes');
      },
      commentCount() {
        return this._.sumBy(this.stats.all || [], 'comments');
      }
    },
    watch: {
      async duration() {
        await this.getChartData();
      }
    },
    methods: {
      updateDate (mon, week) {
        this.endDate = this.$moment(this.endDate).tz('Asia/Seoul').format('YYYY-MM-DD');
        if(mon > 0 || week > 0) {
          this.startDate = this.$moment(this.endDate).subtract(mon, 'months').subtract(week, 'weeks').format('YYYY-MM-DD');
        }
        else {
          this.startDate = this.$moment().subtract(6, 'months').format('YYYY-MM-DD')
        }
      },
      async getChartData() {
        this.viewChartData = [];
        this.clickChartData = [];
        this.likeChartData = [];

        let viewHistories = this.stats.all.filter((history) => {
          if (this.duration === 0 || this.$moment(history.date).tz('Asia/Seoul').isBetween(this.$moment(this.startDate), this.$moment(this.endDate), null, '[]')) {
            return history;
          }
          return false;
        });

        viewHistories = this._.orderBy(viewHistories, ['date'], ['desc']);
        let range = this.$moment().range(this.$moment.max([(viewHistories.length > 0 ? this.$moment(viewHistories[viewHistories.length - 1].date) : this.$moment(this.startDate)), this.$moment(this.startDate)]), this.$moment(this.endDate));
        let dates = Array.from(range.by('days'));
        viewHistories = dates.map((date) => {
          let bFind = this._.find(viewHistories, (history) => {
            return date.isSame(this.$moment(history.date));
          });
          if (!bFind) {
            return {date: date.toDate().toISOString(), views: 0}
          }
          return bFind;
        });

        this.viewChartData.push({
          label: '',
          data: this._.compact(viewHistories.map((history) => {
            if (this.duration === 0 || this.$moment(history.date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]')) {
              return {
                x: history.date,
                y: history.views
              }
            }
            else
              return null
          })),
          backgroundColor: 'rgba(189, 19, 40, .7)',
          hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
          borderColor: 'rgba(189, 19, 40, .7)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBorderWidth: 0,
          fill: false,
          showLine: true,
          // pointHitRadius: 100
        });

        this.clickChartData.push({
          label: '',
          data: this._.compact(viewHistories.map((history) => {
            if (this.duration === 0 || this.$moment(history.date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]')) {
              return {
                x: history.date,
                y: history.clicks
              }
            }
            else
              return null
          })),
          backgroundColor: 'rgba(189, 19, 40, .7)',
          hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
          borderColor: 'rgba(189, 19, 40, .7)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBorderWidth: 0,
          fill: false,
          showLine: true,
          // pointHitRadius: 100
        });

        this.likeChartData.push({
          label: '',
          data: this._.compact(viewHistories.map((history) => {
            if (this.duration === 0 || this.$moment(history.date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]')) {
              return {
                x: history.date,
                y: history.likes + history.comments
              }
            }
            else
              return null
          })),
          backgroundColor: 'rgba(189, 19, 40, .7)',
          hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
          borderColor: 'rgba(189, 19, 40, .7)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBorderWidth: 0,
          fill: false,
          showLine: true,
          // pointHitRadius: 100
        });

        if (this.propositions.length > 0) {
          this.maxView = this._.maxBy(this.propositions, 'views');
          this.maxClick = this._.maxBy(this.propositions, (proposition) => {
            return ((proposition['viewHistories'] || []).length > 0) ? this._.sumBy(proposition['viewHistories'], 'clicks') : 0;
          });
          this.maxComment = this._.maxBy(this.propositions, (proposition) => {
            return ((proposition['viewHistories'] || []).length > 0) ? this._.sumBy(proposition['viewHistories'], 'comments') : 0;
          });
        }
      },
      async onDateChangeHandler(v,type){
        if(type ==='start'){
          this.startDate = v;
        }else{
          this.endDate = v;
        }
        await this.getChartData();
      },
      async csvDownload(){
        const campaignId = this.campaign.id;
        const startDate = this.startDate.replace(/-/gi, '');
        const endDate = this.endDate.replace(/-/gi, '');
  
        try{
          const { data } = await this.axios.get(`/campaign/report/period?campaignId=${campaignId}&startDate=${startDate}&endDate=${endDate}`);
          const initHead = 'date,account,view,click,point,cpv,cpc';
          const sumObj = {};
          const csv = data.reduce((acc,cur) =>{
            if(!sumObj[cur.account]){
              sumObj[cur.account] = {
                cpv : 0, cpc : 0, views : 0
              };
            }

            sumObj[cur.account].cpv += (cur.cpv || 0);
            sumObj[cur.account].cpc += (cur.cpc || 0);
            sumObj[cur.account].views += (cur.views || 0);

            acc.push(`${cur.date},${cur.account},${cur.views || 0},${cur.click || 0},${cur.point || 0},${cur.cpv || 0},${cur.cpc || 0}`);
            return acc;
          },[initHead]).join("\n");

          const sum = Object.entries(sumObj).reduce((acc,[k,{cpv, cpc, views}]) => {
            acc.push(`${k} cpv: ${cpv},${k} cpc: ${cpc}, ${k} views : ${views}`);
            return acc;
          },[]).join('\n');

          let csvFile;
          let downloadLink;
        
          const BOM = "\uFEFF";
          const csv2 = BOM + csv + '\n' + sum;

          csvFile = new Blob([csv2],{type:'text/csv'});
          downloadLink = document.createElement('a');
          downloadLink.download = `${this.startDate}-${this.endDate} 보고서.csv`;
          downloadLink.href = window.URL.createObjectURL(csvFile);
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }catch(error){
          alert('다운로드에 실패했습니다!');
          console.log(error);
        }
      }
    },
    async created() {
      this.endDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
      this.startDate = this.$moment().subtract(6, 'months').format('YYYY-MM-DD');
      await this.getChartData();
    },
  };
</script>
