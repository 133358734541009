<template>
  <div>
    <v-card flat class="subheading my-4" @click="getMetadata({all: true})" style="background-color: white;cursor:pointer">
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="#00C73C">
            <v-icon color="white">mdi-file-chart</v-icon>
          </v-list-item-avatar>
          <v-list-item-content style="min-width: 292px">
            <v-list-item-title>
              <youtubeIcon class="mt-1"/>
              <span class="mb-2 ml-1" style="font-size:16px">캠페인 전체 보고서</span>
              <span v-if="$vuetify.breakpoint.smAndDown" class="ml-1"><span style="font-size:13px; color:#4770bd">상세보기 ></span></span>
            </v-list-item-title>
            <v-row v-if="$vuetify.breakpoint.mdAndUp">
              <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">캠페인의 성과를 확인해보세요.</v-list-item-subtitle>
              <span style="font-size:13px; color:#4770bd">상세보기 ></span>
            </v-row>
            <v-row v-else class="mt-3">
              <v-col>
                <v-list-item-title style="font-size: 13px;color: #484848;">조회수</v-list-item-title>
                <v-list-item-subtitle class="body-1" v-text="viewCount | numFormat" style="color: #484848;"></v-list-item-subtitle>
              </v-col>
              <v-col>
                <v-list-item-title style="font-size: 13px;color: #484848;">링크 클릭수</v-list-item-title>
                <v-list-item-subtitle class="body-1" v-text="clickCount | numFormat" style="color: #484848;"></v-list-item-subtitle>
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-content style="min-width: 110px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px;color: #484848;">집행비용</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{pointSum | numFormat}}원</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px;color: #484848;">조회수/클릭수</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{viewCount | numFormat}}회/{{clickCount | numFormat}}회</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px;color: #484848;">좋아요/댓글수</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{likeCount | numFormat}}개/{{commentCount | numFormat}}개</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px;color: #484848;">CPV</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{(viewCount ? Math.round(pointSum/viewCount) : 0) | numFormat}}원</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px;color: #484848;">CPC</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{(clickCount ? Math.round(pointSum/clickCount) : 0) | numFormat}}원</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-title style="font-size: 13px;color: #484848;">CTR</v-list-item-title>
            <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{(viewCount ? Math.round(clickCount*10000.0/viewCount)/100 : 0) + '%'}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-row v-for="(proposition, idx) in propositions" :key="idx">
      <v-card flat class="mb-4" @click="getMetadata(proposition)" v-if="idx >= ((page - 1) * itemsPerPage) && idx < (itemsPerPage * page)">
        <v-list :two-line="$vuetify.breakpoint.mdAndUp" :three-line="$vuetify.breakpoint.smAndDown" class="listwidth">
          <v-list-item style="background-color: white">
            <v-list-item-avatar>
              <img v-if="(proposition.Influencer || {}).photo" :src="(proposition.Influencer || {}).photo"/>
              <img v-else-if="(proposition.Youtuber || {}).thumbnail" :src="(proposition.Youtuber || {}).thumbnail"/>
              <img v-else src="@/assets/img/matching-placeholder.jpg">
            </v-list-item-avatar>
            <v-list-item-content style="min-width: 292px">
              <v-list-item-title>
                <naverIcon v-if="proposition.taggableType === 'naver'"/>
                <instaIcon v-if="proposition.taggableType === 'insta'"/>
                <youtubeIcon v-if="proposition.taggableType === 'youtube'"/>
                <span class="subheading ml-1" v-if="proposition.Influencer">{{proposition.Influencer.account}}</span>
                <span class="subheading ml-1" v-else-if="proposition.Youtuber">{{proposition.Youtuber.title}}</span>
                <span v-if="$vuetify.breakpoint.smAndDown" class="ml-1"><span style="font-size:13px; color:#4770bd">상세보기 ></span></span>
              </v-list-item-title>
              <v-row v-if="$vuetify.breakpoint.mdAndUp">
                <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{((proposition.Influencer || {}).metadata || {}).title || proposition.cname}}</v-list-item-subtitle>
                <span style="font-size:13px; color:#4770bd">상세보기 ></span>
              </v-row>
              <v-row v-else class="mt-3">
                <v-col>
                  <v-list-item-title style="font-size: 13px;color: #484848;">집행비용</v-list-item-title>
                  <v-list-item-subtitle class="body-1" v-text="proposition.point || 0" style="color: #484848;"></v-list-item-subtitle>
                </v-col>
                <v-col>
                  <v-list-item-title style="font-size: 13px;color: #484848;">조회수 / 클릭수</v-list-item-title>
                  <v-list-item-subtitle class="body-1" style="color: #484848;">{{(proposition.reviewVideo || {}).viewCount | numFormat}}회/{{(_.find(stats.propositions, {id: proposition.id})?.shortUrl?.views || 0) || 0}}회</v-list-item-subtitle>
                </v-col>
                <v-col>
                  <v-list-item-title style="font-size: 13px;color: #484848;">좋아요 / 댓글 수</v-list-item-title>
                  <v-list-item-subtitle class="body-1" style="color: #484848;">{{(proposition.reviewVideo || {}).likeCount | numFormat}}개/{{(proposition.reviewVideo || {}).commentCount}}개</v-list-item-subtitle>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-content style="min-width: 110px" v-if="$vuetify.breakpoint.mdAndUp">
              <v-list-item-title style="font-size: 13px;color: #484848;">집행비용</v-list-item-title>
              <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{proposition.point | numFormat}}원</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
              <v-list-item-title style="font-size: 13px;color: #484848;">조회수 / 클릭수</v-list-item-title>
              <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{(((proposition['viewHistories'] || []).length > 0) ? (_.sumBy(proposition['viewHistories'], 'views') || 0) : 0) | numFormat}}회/{{(_.find(stats.propositions, {id: proposition.id})?.shortUrl?.views || 0) | numFormat}}회</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content style="min-width: 140px" v-if="$vuetify.breakpoint.mdAndUp">
              <v-list-item-title style="font-size: 13px;color: #484848;">좋아요 / 댓글 수</v-list-item-title>
              <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{(((proposition['viewHistories'] || []).length > 0) ? (_.sumBy(proposition['viewHistories'], 'likes') || 0) : 0) | numFormat}}개/{{(((proposition['viewHistories'] || []).length > 0) ? (_.sumBy(proposition['viewHistories'], 'comments') || 0) : 0) | numFormat}}개</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
              <v-list-item-title style="font-size: 13px;color: #484848;">CPV</v-list-item-title>
              <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{(_.sumBy(proposition['viewHistories'], 'views') ? Math.round(proposition.point/(_.sumBy(proposition['viewHistories'], 'views') || 0)) : 0) | numFormat}}원</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
              <v-list-item-title style="font-size: 13px;color: #484848;">CPC</v-list-item-title>
              <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{((_.find(stats.propositions, {id: proposition.id})?.shortUrl?.views || 0) ? Math.round(proposition.point/((_.find(stats.propositions, {id: proposition.id})?.shortUrl?.views || 0))) : 0) | numFormat}}원</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content style="min-width: 100px" v-if="$vuetify.breakpoint.mdAndUp">
              <v-list-item-title style="font-size: 13px;color: #484848;">CTR</v-list-item-title>
              <v-list-item-subtitle class="body-1 mt-3" style="color: #484848;">{{((_.sumBy(proposition['viewHistories'], 'views') || 0) ? Math.round((_.find(stats.propositions, {id: proposition.id})?.shortUrl?.views || 0)*10000.0/(_.sumBy(proposition['viewHistories'], 'views') || 0) || 0)/100 : 0) + '%'}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-row>
    <div class="text-center mt-3" style="background-color: white">
      <v-pagination
          v-model="page"
          :length="(propositions.length - 1)/itemsPerPage + 1"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Youtubereportlist',
    props: ['campaign', 'propositions', 'stats'],
    components: {
      naverIcon: () => import('@/assets/img/campaign/naver_c.svg'),
      instaIcon: () => import('@/assets/img/campaign/insta_c.svg'),
      youtubeIcon: () => import('@/assets/img/campaign/youtube_c.svg'),
    },
    data() {
      return {
        page: 1,
        itemsPerPage: 10
      }
    },
    computed: {
      viewCount() {
        return this._.sumBy(this.stats.all || [], 'views');
      },
      clickCount() {
        return this._.sumBy(this.stats.propositions.map(proposition => proposition.shortUrl) || [], 'views');
      },
      pointSum() {
        return this._.sumBy(this.propositions, 'point');
      },
      likeCount() {
        return this._.sumBy(this.stats.all || [], 'likes');
      },
      commentCount() {
        return this._.sumBy(this.stats.all || [], 'comments');
      }
    },
    watch: {
      $props: {
        immediate: true,
        deep: true,
        handler() {
          this.$forceUpdate();
        }
      }
    },
    methods: {
      async getMetadata(proposition) {
        this.$router.push({ name: "CampaignDetail", params: {index: parseInt(this.$route.params.index)}, query: {tab: 1, id: proposition.id}})
        this.detail=proposition;
        this.$emit('update:detail', this.detail);
      },
    }
  };
</script>
