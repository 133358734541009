<template>
  <v-card flat class="mt-4 pa-8">
    <v-row class="mb-8" style="color: #3A3A3A">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '7'" style="font-size:18px">캠페인 전체 기간 보고서</v-col>
      <v-spacer></v-spacer>
      <v-col cols="4" class="text-right mt-1">{{$moment(stats?.all?.[0].date || new Date()).tz('Asia/Seoul').format('YYYY. MM. DD')}} - {{$moment.tz('Asia/Seoul').format('YYYY. MM. DD')}}</v-col>
      
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          집행 금액
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (pointSum) | numFormat}}원
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          리뷰 등록된 건의 제안금액 합계
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          조회수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (viewCount) | numFormat}}회
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          누적조회수
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          클릭수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{clickCount | numFormat}}회
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          누적 구매링크 클릭수
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          CPV
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (viewCount ? Math.round(pointSum/viewCount) : 0) | numFormat}}원
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          조회수 1회당 소요 비용<br>캠페인 초기의 CPV는 최종 CPV보다 높으며 누적 조회수가 증가할수록 CPV도 감소합니다.
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          CPC
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (clickCount ? Math.round(pointSum/clickCount) : 0) | numFormat}}원
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          구매링크 클릭수 1회당 소요된 비용<br>캠페인 초기의 CPC는 최종 CPC보다 높으며 누적 클릭수가 증가할수록 CPC도 감소합니다.
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          CTR
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (Math.floor(clickCount*10000/viewCount))/100}}%
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          조회수 대비 클릭률
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          상위노출 키워드 수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ keywordCount }}개
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          캠페인 진행 이후 검색결과 20위 이내에 노출된 키워드 수
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          좋아요
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ (likeCount) | numFormat}}개
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          누적 좋아요 수
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4': '6'" class="pr-2 mt-8">
        <v-row style="font-size:16px; color: #3A3A3A">
          댓글
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="mb-2" style="font-size:24px; color: #4463BB">
          {{ commentCount | numFormat}}개
        </v-row>
        <v-row class="caption" style="font-size:13px; color: #808080">
          누적 댓글수
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-12 mb-4" style="color: #3A3A3A">
      <v-col cols="3" class="d-flex align-center" style="font-size:18px">캠페인 기간별 보고서</v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '12'">
        <v-btn-toggle v-model="duration" mandatory>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(0, 0)">
            전체
          </v-btn>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(0, 1)">
            1주
          </v-btn>
          <v-btn class="ma-0" color="rouge" flat outlined style="width: 60px" @click="updateDate(1, 0)">
            1개월
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex align-center mr-8" :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
        <DatePicker :date="startDate" @onDateChangeHandler="v => onDateChangeHandler(v,'start')" :maxDate="endDate"/>
        <div style="margin : 0 8px">-</div>
        <DatePicker :date="endDate" @onDateChangeHandler="v => onDateChangeHandler(v,'end')" :minDate="startDate"/>
      </v-col>
      <v-btn @click="csvDownload" color="rouge" flat outlined>
        <v-icon>download</v-icon>
            보고서 다운받기
      </v-btn>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          조회수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row style="font-size: 32px; color: #4463BB">
          <v-col>
            {{_.sumBy(viewChartData[0].data, 'y') | numFormat}}
          </v-col>
        </v-row>
        <v-row style="font-size: 13px; color: #808080">
          <v-col>
            기간 내 발생한 조회수
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pr-2 mt-8">
        <v-row class="subheading" style="color: #3A3A3A">
          조회수 기록
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="pl-6">
          <naverChart :width="$vuetify.breakpoint.mdAndUp ? 600 : 400" :height="200" :data="viewChartData"></naverChart>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          클릭 수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row style="font-size: 32px; color: #4463BB">
          <v-col>
            {{(duration === 0 ? clickCount : _.sumBy(clickChartData[0].data, 'y')) | numFormat}}
          </v-col>
        </v-row>
        <v-row style="font-size: 13px; color: #808080">
          <v-col>
            기간 내 발생한 구매링크 클릭수
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pr-2 mt-8">
        <v-row class="subheading" style="color: #3A3A3A">
          클릭수 기록
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="pl-6">
          <naverChart :width="$vuetify.breakpoint.mdAndUp ? 600 : 400" :height="200" :data="clickChartData"></naverChart>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          좋아요/댓글수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row style="font-size: 32px; color: #4463BB">
          <v-col>
            {{ (likeCount + commentCount) | numFormat}}
          </v-col>
        </v-row>
        <v-row style="font-size: 13px; color: #808080">
          <v-col>
            기간 내 발생한 좋아요, 댓글 수 합계
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pr-2 mt-8">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          좋아요/댓글수 기록
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row class="pl-6">
          <naverChart :width="$vuetify.breakpoint.mdAndUp ? 600 : 400" :height="200" :data="likeChartData"></naverChart>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'" class="pr-2 mt-8">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          상위노출 키워드 수
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row style="font-size: 32px; color: #4463BB">
          <v-col>
            {{ keywordCount | numFormat}}
          </v-col>
        </v-row>
        <v-row style="font-size: 13px; color: #808080">
          <v-col>
            기간 내 20위 이상 노출된 키워드 수
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'" :class="$vuetify.breakpoint.mdAndUp ? 'pr-2 mt-8' : 'mt-8'">
        <v-row class="subheading mb-1" style="color: #3A3A3A">
          상위 노출 키워드
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-list style="width:100%">
            <v-list-item>
              <v-row>
                <v-col cols="6">
                  <v-list-item-title style="font-size: 13px;color: #757575;">노출중인 키워드</v-list-item-title>
                </v-col>
                <!--                    <v-col cols="3">-->
                <!--                      <v-list-item-title style="font-size: 13px;color: #757575;">키워드 검색량</v-list-item-title>-->
                <!--                    </v-col>-->
                <v-col cols="2">
                  <v-list-item-title style="font-size: 13px;color: #757575;">순위</v-list-item-title>
                </v-col>
                <v-col cols="2">
                  <v-list-item-title style="font-size: 13px;color: #757575;">노출 리뷰 수</v-list-item-title>
                </v-col>
                <v-col cols="2">
                  <v-list-item-title style="font-size: 13px;color: #757575;">노출 리뷰 목록</v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item v-for="(keyword, index) in (keywordsAll ? keywords : _.take(keywords, 5))" :key="index">
              <v-col cols="6">
                <v-list-item-content style="font-size: 16px;color: #484848;">
                  {{keyword.name}}
                </v-list-item-content>
              </v-col>
              <!--                  <v-col cols="3">-->
              <!--                    <v-list-item-content style="font-size: 16px;color: #484848;">-->
              <!--                      {{keyword.keyword.mmqccnt | numFormat}}-->
              <!--                    </v-list-item-content>-->
              <!--                  </v-col>-->
              <v-col cols="2">
                <v-list-item-content style="font-size: 16px;color: #484848;">
                  {{keyword.rank}}위
                </v-list-item-content>
              </v-col>
              <v-col cols="2">
                <v-list-item-content style="font-size: 16px;color: #484848;">
                  {{keyword.count}}개
                </v-list-item-content>
              </v-col>
              <v-col cols="2">
                <v-list-item-content style="font-size: 16px;color: #484848;">
                  <v-menu v-model="keyword.menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-open-in-new</v-icon>
                    </template>
                    <v-card class="pa-4" width="400">
                      <v-card-title>
                        <div>
                          <v-icon color="default" @click="keyword.menu=false">close</v-icon>
                          <br>
                          <span style="font-size:16px">노출 리뷰 목록</span>
                        </div>
                      </v-card-title>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content class="mx-0 px-1" style="width: 50%">작성자</v-list-item-content>
                          <v-list-item-content class="mx-0 px-1 align-end" style="width: 25%">노출순위</v-list-item-content>
                          <v-list-item-action style="width: 25%">리뷰 바로가기</v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                        <div style="height: 240px; overflow-y: auto">
                          <v-list-item v-for="(item, idx) in keyword.list" :key="idx">
                            <v-list-item-content>
                              {{item.username}}
                            </v-list-item-content>
                            <v-list-item-content>
                              {{item.rank}}
                            </v-list-item-content>
                            <v-list-item-action>
                              <a :href="_.find(propositions, {id: item.propositionId}).review" target="_blank"><v-icon>mdi-open-in-new</v-icon></a>
                            </v-list-item-action>
                          </v-list-item>
                        </div>
                        <v-divider></v-divider>
                        <div class="text-right">
                          <v-btn tile class="mt-4 rouge white-two--text" @click="keyword.menu=false">확인</v-btn>
                        </div>
                      </v-list>
                    </v-card>
                  </v-menu>
                </v-list-item-content>
              </v-col>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-row class="text-center py-4" style="cursor:pointer;color:#757575">
              <v-col v-if="!keywordsAll" @click="keywordsAll = true" style="font-size:16px">
                <v-icon color="#757575" class="mb-1" dense>mdi-chevron-down</v-icon> 모두 보기
              </v-col>
              <v-col v-if="keywordsAll" @click="keywordsAll = false" style="font-size:16px">
                <v-icon color="#757575" class="mb-1" dense>mdi-chevron-up</v-icon> 닫기
              </v-col>
            </v-row>
          </v-list>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="(maxView.Influencer || {}).metadata" :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'" class="pr-2 mt-8">
        <v-row class="subheading" style="color: #3A3A3A">
          조회수 가장 많은 게시물
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="12">
            <v-img v-if="(maxView.Influencer || {}).metadata" aspect-ratio="2" :src="(maxView.Influencer || {}).metadata.image ? (maxView.Influencer || {}).metadata.image : 'https://via.placeholder.com/320x160.jpg?text=Supermebers'"></v-img>
            <v-row v-if="(maxView.Influencer || {}).metadata" class="justify-space-between mt-2" justify="center" align="center">
              {{(maxView.Influencer || {}).metadata.title}}
              <a class="pa-4" :href="maxView.review" target="_blank"><v-icon>open_in_new</v-icon></a>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="(maxClick.Influencer || {}).metadata" :cols="$vuetify.breakpoint.mdAndUp ? '6' : '12'" class="pr-2 mt-8">
        <v-row class="subheading" style="color: #3A3A3A">
          클릭수 가장 많은 게시물
        </v-row>
        <v-divider class="my-2"></v-divider>
        <v-row>
          <v-col cols="12">
            <v-img v-if="(maxClick.Influencer || {}).metadata" aspect-ratio="2" :src="(maxClick.Influencer || {}).metadata.image ? (maxClick.Influencer || {}).metadata.image : 'https://via.placeholder.com/320x160.jpg?text=Supermebers'"></v-img>
            <v-row v-if="(maxClick.Influencer || {}).metadata" class="justify-space-between mt-2" justify="center" align="center">
              {{(maxClick.Influencer || {}).metadata.title}}
              <a class="pa-4" :href="maxClick.review" target="_blank"><v-icon>open_in_new</v-icon></a>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import naverChart from '@/components/campaign/detail/report/NaverChart'
import DatePicker from '@/components/campaign/detail/report/DatePicker'

  export default {
    name: 'NaverAllreport',
    props: ['campaign', 'propositions', 'stats'],
    components: {
      naverChart,
      DatePicker
    },
    data() {
      return {
        viewChartData: [],
        clickChartData: [],
        likeChartData: [],
        keywords: [],
        keywordsAll: false,
        maxView: {},
        maxClick: {},
        duration: 0,
        startDate: '',
        endDate: ''
      }
    },
    computed: {
      viewCount() {
        return this._.sumBy(this.stats.all, 'views');
      },
      clickCount() {
        return this._.sumBy(this.stats.propositions, (proposition) => ((proposition.shortUrl || {}).views || 0));
      },
      pointSum() {
        return this._.sumBy(this.propositions, 'point');
      },
      likeCount() {
        return this._.sumBy(this.stats.all, 'likes');
      },
      commentCount() {
        return this._.sumBy(this.stats.all, 'comments');
      },
      keywordCount() {
        return this._.sumBy(this.propositions, (proposition) => {
          return proposition.keywords.filter((keyword) => {
            return (keyword.rank <= 20);
          }).length;
        });
      }
    },
    watch: {
      async duration() {
        await this.getChartData();
      }
    },
    methods: {
      updateDate (mon, week) {
        this.endDate = this.$moment(this.endDate).tz('Asia/Seoul').format('YYYY-MM-DD');
        if(mon > 0 || week > 0) {
          this.startDate = this.$moment(this.endDate).subtract(mon, 'months').subtract(week, 'weeks').format('YYYY-MM-DD');
        }
        else {
          this.startDate = this.$moment(this.stats?.all?.[0].date || new Date()).tz('Asia/Seoul').format('YYYY-MM-DD');
        }
      },
      async getChartData() {
        this.viewChartData = [];
        this.clickChartData = [];
        this.likeChartData = [];

        let viewHistories = this.stats.all.filter((history) => {
          if (this.duration === 0 || this.$moment(history.date).tz('Asia/Seoul').isBetween(this.$moment(this.startDate), this.$moment(this.endDate), null, '[]')) {
            return history;
          }
          return false;
        });

        viewHistories = this._.orderBy(viewHistories, ['date'], ['desc']);
        let range = this.$moment().range(this.$moment.max([(viewHistories.length > 0 ? this.$moment(viewHistories[viewHistories.length - 1].date) : this.$moment(this.startDate)), this.$moment(this.startDate)]), this.$moment(this.endDate));
        let dates = Array.from(range.by('days'));
        viewHistories = dates.map((date) => {
          let bFind = this._.find(viewHistories, (history) => {
            return date.isSame(this.$moment(history.date));
          });
          if (!bFind) {
            return {date: date.toDate().toISOString(), views: 0}
          }
          return bFind;
        });

        this.viewChartData.push({
          label: '',
          data: this._.compact(viewHistories.map((history) => {
            if (this.duration === 0 || this.$moment(history.date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]')) {
              return {
                x: history.date,
                y: history.views
              }
            }
            else
              return null
          })),
          backgroundColor: 'rgba(189, 19, 40, .7)',
          hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
          borderColor: 'rgba(189, 19, 40, .7)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBorderWidth: 0,
          fill: false,
          showLine: true,
          // pointHitRadius: 100
        });

        this.clickChartData.push({
          label: '',
          data: this._.compact(viewHistories.map((history) => {
            if (this.duration === 0 || this.$moment(history.date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]')) {
              return {
                x: history.date,
                y: history.clicks
              }
            }
            else
              return null
          })),
          backgroundColor: 'rgba(189, 19, 40, .7)',
          hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
          borderColor: 'rgba(189, 19, 40, .7)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBorderWidth: 0,
          fill: false,
          showLine: true,
          // pointHitRadius: 100
        });

        this.likeChartData.push({
          label: '',
          data: this._.compact(viewHistories.map((history) => {
            if (this.duration === 0 || this.$moment(history.date).tz('Asia/Seoul').isBetween(this.startDate, this.endDate, null, '[]')) {
              return {
                x: history.date,
                y: history.likes + history.comments
              }
            }
            else
              return null
          })),
          backgroundColor: 'rgba(189, 19, 40, .7)',
          hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
          borderColor: 'rgba(189, 19, 40, .7)',
          borderWidth: 2,
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBorderWidth: 0,
          fill: false,
          showLine: true,
          // pointHitRadius: 100
        });

        let keywords = this._.reduce(this.propositions, (result, proposition) => {
          result = this._.union(result, proposition.keywords)
          return result;
        }, []);

        let grouped = this._.groupBy(keywords, 'campaignKeywordId');
        this.keywords = [];
        Object.keys(grouped).map((campaignKeywordId) => {
          let list = this._.orderBy(grouped[campaignKeywordId], ['rank'], ['asc']);
          this.keywords.push({
            name: grouped[campaignKeywordId][0].campaignKeyword.name,
            rank: list[0].rank,
            count: grouped[campaignKeywordId].length,
            list: list
          });
        });
        this.keywords = this._.orderBy(this.keywords, ['rank'], ['asc']);

        if (this.propositions.length > 0) {
          this.maxView = this._.maxBy(this.propositions, 'views');
          let maxViewMetadata = await this.axios.get('/utils/metadata?url=' + this.maxView.review);
          this.maxView.Influencer.metadata = maxViewMetadata.data;

          this.maxClick = this._.maxBy(this.propositions, (proposition) => {
            return ((proposition['viewHistories'] || []).length > 0) ? this._.sumBy(proposition['viewHistories'], 'clicks') : 0;
          });
          let maxClickMetadata = await this.axios.get('/utils/metadata?url=' + this.maxClick.review);
          this.maxClick.Influencer.metadata = maxClickMetadata.data;
        }
      },
      async onDateChangeHandler(v, type){
        if(type ==='start'){
          this.startDate = v;
        }else{
          this.endDate = v;
        }
        await this.getChartData();
      },
      async csvDownload(){
        const campaignId = this.campaign.id;
        const startDate = this.startDate.replace(/-/gi, '');
        const endDate = this.endDate.replace(/-/gi, '');
  
        try{
          const { data } = await this.axios.get(`/campaign/report/period?campaignId=${campaignId}&startDate=${startDate}&endDate=${endDate}`);
          const initHead = '날짜,계정,조회수,클릭,좋아요,댓글,일 평균 방문자 수,원고료,cpv,cpc';
          const sumObj = {};
          const csv = data.reduce((acc,cur) =>{
            if(!sumObj[cur.account]){
              sumObj[cur.account] = {
                cpv : 0, cpc : 0, views : 0
              };
            }

            sumObj[cur.account].cpv += (cur.cpv || 0);
            sumObj[cur.account].cpc += (cur.cpc || 0);
            sumObj[cur.account].views += (cur.views || 0);

            acc.push(`${cur.date},${cur.account},${cur.views || 0},${cur.click || 0},${cur.like || 0},${cur.comment || 0},${cur.visitor || 0},${cur.point || 0},${cur.cpv || 0},${cur.cpc || 0}`);
            return acc;
          },[initHead]).join("\n");

          const sum = Object.entries(sumObj).reduce((acc,[k,{cpv, cpc, views}]) => {
            acc.push(`평균 ${k} cpv: ${cpv},평균 ${k} cpc: ${cpc}, ${k} 총 조회수 : ${views}`);
            return acc;
          },[]).join('\n');

          let csvFile;
          let downloadLink;
        
          const BOM = "\uFEFF";
          const csv2 = BOM + csv + '\n' + sum;

          csvFile = new Blob([csv2],{type:'text/csv'});
          downloadLink = document.createElement('a');
          downloadLink.download = `${this.startDate}-${this.endDate} 보고서.csv`;
          downloadLink.href = window.URL.createObjectURL(csvFile);
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }catch(error){
          alert('다운로드에 실패했습니다!');
          console.log(error);
        }
      }
    },
    async created() {
      this.endDate = this.$moment.tz('Asia/Seoul').format('YYYY-MM-DD');
      this.startDate = this.$moment(this.stats?.all?.[0]?.date || new Date()).tz('Asia/Seoul').format('YYYY-MM-DD');
      await this.getChartData();
    },
  };
</script>
